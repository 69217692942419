
import Vue from 'vue';

import HomeBanner from './components/HomeBanner.vue'
import Articulo from './components/Articulo.vue'
import Filtros from './components/Filters.vue'
import Sponsor from './components/Sponsor.vue';


export default Vue.extend({
  name: 'App',

  components: {
    HomeBanner,
    Articulo,
    Filtros,
    Sponsor
  },

  data: () => ({

  }),
});
