<template>
	<v-container class="section">
		<h2 class="title text-center pb-3 pt-2">
			Agradecemos el apoyo de nuestros colaboradores
		</h2>
		<v-row justify="center" class="mt-3">
			<v-col v-for="sponsor in sponsors" :key="sponsor.name" cols="12" md="6" lg="4" class="d-flex justify-center px-4">
				<v-img :src="sponsor.img" width="266.67px" height="83.33px" class="sponsor-img" contain></v-img>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
export default {
  name: "SponsorSection",
	data: () => ({
		sponsors: [
			{
				name: "BlueExpress",
				img: require("@/assets/logo_bluexpress.png"),
			},
			{
				name: "Transbank",
				img: require("@/assets/logo_transbank_color.png"),
			}
		]
	}),

}
</script>
<style scoped>
.section {
	background-color: #fa4f0015;
	max-width: 100%;
	padding: auto;
	padding-bottom: 50px;
	margin-bottom: auto;
	border-bottom: 0.5px solid #fa4f00;
	border-top: 0.5px solid #fa4f00;

}
.title {
	color: #fa4f00;
	font-size: 26px;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.126);
}
.sponsor-img {
	opacity: 1 !important;
}
</style>