<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        sm="4"
        v-for="(emprendedor, index) in emprendedores"
        :key="index"
        class="mb-5"
      >
        <v-card
          v-show="emprendedor.visible"
          class="mx-auto"
          max-width="800"
          elevation="4"
        >
          <v-avatar
          style="
                position: relative;
                top: -30px;
                left: -10px;
                box-shadow: rgba(0, 0, 0, 0.5) 0px 3px 18px;
              "
              width="80px"
              class="pa-1"
              height="80px"
              color="grey lighten-3" 
            >

        <img
                :src="require(`@/assets/img/pymes/logos/${emprendedor.logo}`)"
                alt="Logo"
                style="object-fit: scale-down"
              />
            </v-avatar>

          <div class="d-flex flex-column align-center mt-n12">
          <v-card-title class="custom-title text-center" style="word-break: break-word;">
            {{ emprendedor.nombre.toUpperCase() }}
          </v-card-title>

            <v-card-subtitle class="custom-subtitle text-center grey--text" >
                {{ emprendedor.categoria }}
              </v-card-subtitle>
          </div>

          
          <div>
            <v-sheet class="mx-auto" max-width="800">
              <v-slide-group class="pa-2" active-class="success" show-arrows>
                <v-slide-item
                  v-for="(image, imageIndex) in emprendedor.fotos"
                  :key="imageIndex"
                  v-slot="{ active, toggle }"
                >
                  <v-card
                    :color="active ? undefined : 'grey lighten-1'"
                    class="ma-3"
                    height="220"
                    width="220"
                    @click="toggle"
                  >
                    <v-img
                      height="220"
                      width="220"
                      :src="
                        require(`@/assets/img/pymes/products/${image.trim()}`)
                      "
                      aspect-ratio="1"
                      class="grey lighten-2"
                      :key="imageIndex"
                      alt="Producto navideño"
                      @click="zoom({ image })"
                    > 
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-1"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          >
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </v-sheet>

            <v-card-text class="custom-description text--primary">
              <div
                v-if="
                  emprendedor.descripcion.length > 100 &&
                  emprendedor.showMore == false
                "
              >
                {{ emprendedor.descripcion.substring(0, 100) }}
                <v-btn
                  plain
                  small
                  class="text-capitalize"
                  @click="emprendedor.showMore = true"
                >
                  ver más...
                </v-btn>
              </div>
              <div v-else>{{ emprendedor.descripcion }}</div>
            </v-card-text>
          </div>

          <div class="d-flex flex-column align-center">

          <v-col cols="10" class="text-center">
            <span
              itemprop="author"
              itemscope
              itemtype="https://schema.org/Person"
            >
              <v-btn
                color="primary"
                elevation="2"
                icon
                :href="emprendedor.web"
                target="_blank"
                v-show="emprendedor.web != undefined ? true : false"
              >
                <v-icon color="light blue">fa-globe</v-icon>
              </v-btn>
            </span>

            <v-btn
              color="primary"
              elevation="2"
              icon
              :href="'mailto:' + emprendedor.email"
              target="_blank"
              v-show="emprendedor.email != undefined ? true : false"
            >
              <v-icon color="#dd4b39">fa-envelope</v-icon>
            </v-btn>

            <v-btn
              color="primary"
              elevation="2"
              icon
              :href="`https://wa.me/${emprendedor.numero_telefono}`"
              target="_blank"
              v-show="
                emprendedor.numero_telefono != undefined ? true : false
              "
            >
              <v-icon color="#25d366">fa-whatsapp</v-icon>
            </v-btn>

            <v-btn
              color="primary"
              elevation="2"
              icon
              :href="`${emprendedor.instagram}`"
              target="_blank"
              v-show="emprendedor.instagram != undefined ? true : false"
            >
              <v-icon color="#ac2bac">fa-instagram</v-icon>
            </v-btn>

            <v-btn
              color="primary"
              elevation="2"
              icon
              :href="emprendedor.facebook"
              target="_blank"
              v-show="emprendedor.facebook != undefined ? true : false"
            >
              <v-icon color="#3b5998">fa-facebook</v-icon>
            </v-btn>
          </v-col>
          </div>

        </v-card>
      </v-col>

      <div ref="endOfPage"></div>
    </v-row>

    <v-dialog v-if="dialog" v-model="dialog" width="500">
      <v-img
        :src="require(`@/assets/img/pymes/products/${selectedImage.trim()}`)"
        aspect-ratio="1"
        class="grey lighten-2"
      >
      </v-img>
    </v-dialog>
    <v-snackbar v-model="loading" top>
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" location="center">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import jsonData from "../entrepreneurs.json";
import { EventBus } from "../main";

export default {
  name: "ArticulosEmpred",
  data: () => ({
    emprendedores: [{}],
    empredfilter: [{}],
    page: 2,
    limit: 10,
    filtros: [],
    imgs: [],
    selectedImage: null,
    dialog: false,
    loading: false,
  }),

  created() {
    this.emprendedores = jsonData.entrepreneurs
      .filter((x) => x.visible == true)
      .slice(0, 10);

    EventBus.$on("filtrosCategoria", (filtro) => {
     this.filtros = filtro;
     this.emprendedores =  this.getEmprededores();

    });
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    
    loadMoreEmprendedores() {
      const empredfilter = this.getEmprededores();

      const start = (this.page - 1) * this.limit;
      const end = start + this.limit;



      const newEmprendedores = empredfilter.slice(start, end);

      this.emprendedores = [...this.emprendedores, ...newEmprendedores];
      this.page++;
    },
    handleScroll() {
      const endOfPage = this.$refs.endOfPage.offsetTop;
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight;
      if (scrollTop + windowHeight >= endOfPage) {
        this.loadMoreEmprendedores();
      }
    },

    zoom(urlImg) {
      console.log(urlImg);
      this.selectedImage = urlImg.image;
      this.dialog = true;
    },

  getEmprededores(){
    let valores = [];
    if (this.filtros.length > 0){

      const empredfilter = jsonData.entrepreneurs.filter(
        (x) => x.visible == true
      );
      empredfilter.forEach((empred) => {
          this.filtros.forEach((filt) => {
            if (filt == "Otros") {
              if (
                empred.categoria != "Gastronomía y alimentos" &&
                empred.categoria != "Decoración, Hogar y Jardín" &&
                empred.categoria != "Deportes y actividades al aire libre" &&
                empred.categoria != "Joyas y accesorios" &&
                empred.categoria != "Mascotas" &&
                empred.categoria != "Niñ@s e Infantes" &&
                empred.categoria != "Salud y Belleza" &&
                empred.categoria != "Servicios" &&
                empred.categoria != "Vestuario y Calzado"
              ) {
                valores.push(empred);
              }
            } else if (empred.categoria == filt) {
              //console.log(empred)
              valores.push(empred);
            }
          });
        });
        return valores;
      }
      else {
        return jsonData.entrepreneurs.filter(
        (x) => x.visible == true
      );}

      
      
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
}
};
</script>
<style>

@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@600&family=Montserrat:wght@500&display=swap');

.custom-title {
  font-family: 'Barlow', sans-serif;
  font-size: x-large;

}
.custom-subtitle {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
}
.custom-description{
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
}


</style>
